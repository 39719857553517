import React from "react"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import useHindiLangFont from "../hooks/useHindiLangFont"
import { SassyText, Section, SectionWrapper, Title } from "./core"

const VideoSection = ({
    videoLink="https://www.youtube.com/embed/TXAJHwKV_0I",
    style={},
    wrapperStyle={paddingTop: "0"}
}) => {
    useTranslation('videoSection')
    const {hindiBlack} = useHindiLangFont()
    return (
        <Section background="#fff" style={style}>
            <SectionWrapper style={wrapperStyle}>
                <Title fontSize="36px" mfontSize="24px" fontWeight="700" style={{width: "fit-content", margin: "auto"}}>
                    <Trans i18nKey="VideoSectionTitle" className={hindiBlack}>Watch the <SassyText>video</SassyText> to know more</Trans>
                </Title>
                <VideoContainer>
                    <VideoWrapper>
                        <iframe style={{borderRadius: "16px"}} width="100%" height="100%" src={`${videoLink}?autoplay=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </VideoWrapper>
                </VideoContainer>
            </SectionWrapper>
        </Section>
    )
}

const VideoContainer = styled(SectionWrapper)`
    padding-top: 0; 
    padding-bottom: 0; 
    margin-top: 46px;
    margin-bottom: 40px;
    width: min(972px, 100%);
    @media (max-width: 768px) {
        margin-top: 24px;
        width: min(450px, 100%);
    }
`

const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    /* force height to 0 and create the container using paddings */
    /* setting padding for any one side to 56% creates a box of 16:9 aspect ratio */
    /* Ref: https://stackoverflow.com/a/35153397/17718114 */
    padding-bottom: 56.25%;
    & iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
`

export default VideoSection